export const juryVoting = () => {
    const $juryVotingContainer = $('.js-container-jury-voting')

    if ($juryVotingContainer.length === 0) {
        return
    }

    /* Selectors */
    const VOTING_SQUARE_HINT = '.js-voting-icon-square-hint'
    const VOTING_ICON_WATCHED = '.js-voting-icon--watched'
    const MODAL_VIDEO = '.js-modal-video'

    /* Paths & URLs */
    const IMAGE_PATH = '/content/themes/jugend-creativ/images'
    const VIMEO_URL = 'https://player.vimeo.com/video/VIDEOID'

    /**
     * set classes on buttons for styling
     * change icons and positions
     * open modals
     */
    const $buttonWatched = $('.js-voting-controls--watched')
    const $buttonWatchedIcon = $(VOTING_ICON_WATCHED)
    const $buttonWinner = $('.js-voting-controls--winner.js-voting-active')
    const $buttonWinnerIcon = $('.js-voting-icon--winner.js-voting-active')
    const $rankButton = $('.js-number-wrapper')
    const $rankHint = $('.js-number-hint')
    const $rankNumber = $('.js-number')
    const $rankRemove = $('.js-remove-icon')
    const $videoPreviewImage = $('.js-voting-video-thumbnail')
    const $associationWinnerButton = $('.js-associaton-finalize')
    const $assictiationVotingSelect = $('.js-placement-select')

    const isAssociationVoting = $('body').hasClass('jury-voting-template-association')
    const numberOfVideos = $('.js-video-list').data('videocount')

    /* css-class names */
    const ACTIVE = 'active'
    const CHANGED_ICON = 'changed-icon'

    /* Modals */
    const $modalWinner = $('.js-modal-winner')
    const $modalWinnerConfirmation = $('.js-modal-winner__confirmation')
    const $associationWinnerFinalizeModal = $('.js-modal-winner-finalisation')
    const $modalVideo = $(MODAL_VIDEO)
    const $vimeoPlayer = $modalVideo.find('.js-video-player')

    const resetIframeUrl = ($iframe) => {
        $vimeoPlayer.attr('data-video', '')
        if ($iframe.length) {
            $iframe.attr('src', 'about:blank')
        }
    }

    const setIframeUrl = ($iframe, vimeoId) => {
        $vimeoPlayer.attr('data-video', vimeoId)
        if ($iframe.length) {
            $iframe.attr('src', VIMEO_URL.replace('VIDEOID', vimeoId))
        }
    }

    const setVideoPreviewImage = (url) => {
        const $figure = $vimeoPlayer.find('.js-video-player-thumb')
        const $img = $('<img />').attr('src', url)

        $figure.find('img').remove()
        $figure.append($img)
    }

    const setDetailsModalTexts = (texts) => {
        $('.js-details-title').text(texts.title)
        $('.js-details-count').text(texts.count)
        $('.js-details-age').text(texts.age)
        $('.js-details-description').text(texts.description)
        $('.js-details-name').text(texts.author_name)
        $('.js-details-group-name').text(texts.group_name)
        $('.js-details-address').text(texts.address)
        $('.js-details-mail').text(texts.mail).attr('href', 'mailto:' + texts.mail) //eslint-disable-line
        $('.js-details-participant').text(texts.school_participant)
        $('.js-details-school').text(texts.school)
        $('.js-details-class').text(texts.class)
        $('.js-details-tutor').text(texts.tutor)
        $('.js-details-bank').text(texts.bank)
    }

    const setWinnerModalButton = (videoId) => {
        $modalWinnerConfirmation.attr('data-videoid', videoId)
    }

    const setWatchedStatus = (status, videoId) => {
        $.ajax({
            type: 'POST',
            url: '/',
            dataType: 'json',
            data: {
                submission_id: videoId,
                watched_status_bank: status,
                association_voting: isAssociationVoting,
            },
        })
    }

    const setWinnerStatus = (videoId, token) => {
        $.ajax({
            type: 'POST',
            url: '/',
            dataType: 'json',
            data: {
                submission_id: videoId,
                winner_status_bank: true,
                bank_token: token,
            },
        }).done(() => {
            location.reload(true) // eslint-disable-line no-restricted-globals
        })
    }

    // https://ourcodeworld.com/articles/read/444/how-to-retrieve-all-or-specific-get-parameters-from-a-url-with-javascript
    const getAssociationToken = () => {
        const regex = new RegExp('[?&]association_token=([^&#]*)', 'i')
        const value = regex.exec(window.location.href)

        return value ? value[1] : undefined
    }

    // https://ourcodeworld.com/articles/read/444/how-to-retrieve-all-or-specific-get-parameters-from-a-url-with-javascript
    const getBankToken = () => {
        const regex = new RegExp('[?&]bank_token=([^&#]*)', 'i')
        const value = regex.exec(window.location.href)

        return value ? value[1] : undefined
    }

    const setPlacementModal = (videoId) => {
        const token = getAssociationToken()
        $assictiationVotingSelect.attr('data-videoid', videoId)

        /* eslint-disable */
        $.ajax({
            type: 'GET',
            url: '/',
            dataType: 'json',
            data: {
                token: token,
                get_placement: true,
                submission_id: videoId
            },
        }).done((data) => {
            $('.js-placement-1').text(data['1'])
            $('.js-placement-2').text(data['2'])
            $('.js-placement-3').text(data['3'])

            $assictiationVotingSelect.find('option').each((i, elem) => {
                const $option = $(elem)
                if ($option.val() == data['current']) {
                    $option.attr('selected', 'selected')
                }
                if (
                    (numberOfVideos >= 3 && data['count_placement'] === 3) ||
                    (numberOfVideos < 3 && data['count_placement'] === numberOfVideos)
                ) {
                    $modalWinnerConfirmation.prop('disabled', false)
                }
            })
        })
        /* eslint-enable */
    }

    const winnerButtonClick = (e) => {
        if (!$(e.target).hasClass(ACTIVE)) {
            $modalWinner.modal('show')
            setWinnerModalButton($(e.target).data('videoid'))
            setPlacementModal($(e.target).data('videoid'))
        }
    }

    $buttonWinner.on('click', winnerButtonClick)

    // Click-handler for buttons
    $buttonWatched.on('click', (e) => {
        const $hint = $(e.target).children(VOTING_SQUARE_HINT)
        const videoId = $(e.target).data('videoid')
        if (!$(e.target).hasClass(ACTIVE)) {
            $hint.text('Markierung entfernen')
            $(e.target).addClass(ACTIVE)
            $(e.target).trigger('mouseenter')
            setWatchedStatus(true, videoId)
        } else {
            $hint.text('Als angesehen markieren')
            $(e.target).removeClass(ACTIVE)
            $(e.target).trigger('mouseleave')
            setWatchedStatus(false, videoId)
        }

        $hint.toggleClass('text-position-center')
    })

    // Rank remove click handler
    $rankRemove.on('click', (e) => {
        const videoId = $(e.target).closest('[data-videoid]').data('videoid')
        const associationToken = getAssociationToken()
        /* eslint-disable */
        $.ajax({
            type: 'POST',
            url: '/',
            dataType: 'json',
            data: {
                token: associationToken,
                submission_id: videoId,
                remove_placement: true,
            },
        }).done(function () {
            location.reload(true) // eslint-disable-line no-restricted-globals
        })
        /* eslint-enable */
    })


    // Hover handlers for watched button
    $buttonWatched.on('mouseenter', (e) => {
        if ($(e.target).hasClass(ACTIVE)) {
            const icon = $(e.target).before()
            icon.attr('src', `${IMAGE_PATH}/button_watched-remove.png`)
            icon.addClass(CHANGED_ICON)
        }
    })

    $buttonWatched.on('mouseleave', (e) => {
        const icon = $(e.target).before()
        icon.attr('src', `${IMAGE_PATH}/button_watched.png`)
        icon.removeClass(CHANGED_ICON)
    })

    // Hover handlers for rank button
    $rankButton.on('mouseenter', (e) => {
        $buttonWinner.off('click', winnerButtonClick)
        const target = $(e.target)
        target.find($rankNumber).hide()
        target.find('.js-remove-icon').show()
        target.find($rankHint).show()
    })

    $rankButton.on('mouseleave', (e) => {
        $buttonWinner.on('click', winnerButtonClick)
        const target = $(e.target)
        target.find($rankNumber).show()
        target.find('.js-remove-icon').hide()
        target.find($rankHint).hide()
    })

    // Video preview image click handler
    $videoPreviewImage.on('click', (e) => {
        const iframe = $vimeoPlayer.find('iframe')
        resetIframeUrl(iframe)
        const videoId = $(e.target).data('videoid')

        $.ajax({
            url: `/?submission_id=${videoId}`,
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        }).done((data) => {
            setIframeUrl(iframe, data.response.vimeo_id)
            setVideoPreviewImage(data.response.thumbnail)
            setDetailsModalTexts(data.response)
            $modalVideo.modal('show')
        })
    })

    // Modal winner confirmation click handler
    $modalWinnerConfirmation.on('click', (e) => {
        const token = getBankToken()
        setWinnerStatus($(e.target).data('videoid'), token)
    })

    // Trigger events for icons
    $buttonWatchedIcon.on('click', (e) => {
        $(e.target).next().trigger('click')
    })

    $buttonWinnerIcon.on('click', (e) => {
        $(e.target).next().trigger('click')
    })

    $buttonWatchedIcon.on('mouseenter', (e) => {
        $(e.target).next().trigger('mouseenter')
    })

    $buttonWatchedIcon.on('mouseleave', (e) => {
        $(e.target).next().trigger('mouseleave')
    })

    $rankNumber.on('mouseenter', (e) => {
        $(e.target).parent().trigger('mouseenter')
    })

    $rankNumber.on('mouseleave', (e) => {
        $(e.target).parent().trigger('mouseleave')
    })

    // Association voting select change handler
    $assictiationVotingSelect.on('change', (e) => {
        const placement = $(e.target).val()
        const videoId = $(e.target).data('videoid')
        const token = getAssociationToken()

        /* eslint-disable */
        $.ajax({
            type: 'POST',
            url: '/',
            dataType: 'json',
            data: {
                token: token,
                submission_id: videoId,
                placement: placement,
            },
        }).done(function () {
            location.reload(true) // eslint-disable-line no-restricted-globals
        })
        /* eslint-enable */

        $modalWinner.modal('hide')
    })

    // Association winner button click handler
    $associationWinnerButton.on('click', () => {
        $associationWinnerFinalizeModal.modal('show')
    })

    setPlacementModal(0)
}
